import { CoreBackButton, CoreButton, CoreTitle } from '@packages';
import { Col, message, Row } from 'antd';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { RegexValidators } from '@config/regex-validator-constant';
import { useForm } from 'react-hook-form';
import { CRS_OPTIONS } from '@utils/CRSOptions';
import { IAddEntityPayload } from '@models/organization.model';
import { defaultCountryCode } from '@config/default-value';
import { OrganizationAPI } from '@service/organization.service';
import { useNavigate, useParams } from 'react-router-dom';
import { ICountry } from '@models/generic.model';
import { useEffect, useMemo } from 'react';
import countryList from 'react-select-country-list';
import { useAppDispatch } from '@store/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import './OrganizationEntityUpdate.scss';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import GenericPostalCode from '@components/common/GenericPostalCode';
import { useTranslation } from 'react-i18next';
import GenericLegalRegistrationName from '@components/common/GenericLegalRegistrationName';
import GenericStreetName from '@components/common/GenericStreetName';
import GenericInputMaxLimit from '@components/common/GenericInputMaxLimit';
import { getEntity } from '@store/slices/entitySlice';
import { EntityAPI } from '@service/entity.service';
import { getSubscription } from '@store/slices/subscribeSlice';

const OrganizationEntityUpdate = () => {
    const { t } = useTranslation();
    const { organization } = useSelector((state: RootState) => state.organization);
    const { entity } = useSelector((state: RootState) => state.entity);
    const { entityId } = useParams();
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);

    const {
        control,
        handleSubmit,
        watch,
        setValue: setFormValue,
        formState: { errors },
        reset,
    } = useForm<IAddEntityPayload>({
        mode: 'onTouched',
        defaultValues: {
            TaxScheme: 'VAT',
        },
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (entityId) {
            dispatch(getEntity(entityId));
        }
    }, []);

    useEffect(() => {
        reset(entity || undefined);
    }, [entity]);

    const navigate = useNavigate();

    const onSubmit = async (data: IAddEntityPayload) => {
        const prepareData = { ...data };
        prepareData.Address.AdditionalNumber = +data?.Address?.AdditionalNumber;
        prepareData.Address.BuildingNumber = +data?.Address?.BuildingNumber;
        prepareData.Address.PostalCode = +data?.Address?.PostalCode;
        prepareData.Address.CountryCode = data?.Address?.CountryCode || defaultCountryCode;

        const payload: IAddEntityPayload = {
            Address: prepareData.Address,
            Contact: prepareData.Contact,
            VatRegistrationNumber: prepareData.VatRegistrationNumber,
            CompanyRegistrationNumber: prepareData.CompanyRegistrationNumber,
            CompanyRegistrationScheme: prepareData.CompanyRegistrationScheme,
            LegalRegistrationName: prepareData.LegalRegistrationName,
            LegalRegistrationNameEn: prepareData.LegalRegistrationNameEn,
            TaxScheme: prepareData.TaxScheme,
            OrganizationId: organization?.OrganizationId || '',
            Email: prepareData.Email,
            Fax: prepareData.Fax,
        };

        payload.EntityId = entity?.EntityId;
        try {
            await EntityAPI.updateEntity(payload);
            message.success(t('ENTITY_UPDATED_SUCCESSFULLY'));
            await dispatch(getSubscription());
            goBack();
            if (entityId) {
                dispatch(getEntity(entityId));
                reset(entity || undefined);
            }
        } catch {
            message.error(t('ENTITY_UPDATE_FAILED'));
        }
    };

    const goBack = () => {
        navigate(`/organizations/${organization?.OrganizationId}/entity/${entityId}`);
    };

    return (
        <div className='update-entity'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='entity-title-area'>
                    <CoreBackButton text={''} />
                    <CoreTitle tag='h1' text={t('UPDATE_ENTITY')} />
                </div>
                <div className='update-entity-form-wrapper'>
                    <Row gutter={16}>
                        <Col span={12}>
                            <GenericLegalRegistrationName
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='LegalRegistrationName'
                                level={t('LEGAL_REGISTRATION_NAME_ARABIC')}
                                marginBottom={20}
                                type='arabic'
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('LEGAL_REGISTRATION_NAME_ONLY_ALLOW_ARABIC'),
                                    },
                                    maxLength: 1000,
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <GenericLegalRegistrationName
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='LegalRegistrationNameEn'
                                level={t('LEGAL_REGISTRATION_NAME_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t(
                                            'LEGAL_REGISTRATION_NAME_ONLY_ALLOW_ENGLISH_CHARACTER',
                                        ),
                                    },
                                    maxLength: 1000,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InlineGenericSelect
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='CompanyRegistrationScheme'
                                level={t('OTHER_SELLER_ID_TYPE')}
                                placeHolder={t('OTHER_SELLER_ID_TYPE')}
                                options={CRS_OPTIONS}
                                setFormValue={setFormValue}
                                rules={{
                                    required: true,
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                disabled={
                                    watch('CompanyRegistrationScheme') === undefined ||
                                    watch('CompanyRegistrationScheme') === ''
                                }
                                dataKey='CompanyRegistrationNumber'
                                level={t('OTHER_SELLER_ID')}
                                marginBottom={20}
                                type='otherSellerId'
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ONLY_NUMERIC,
                                        message: t('OTHER_SELLER_ID_NUMERIC'),
                                    },
                                    maxLength: 10,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InlineGenericInput
                                tooltip={t(
                                    'VAT_REGISTRATION_NUMBER_REFERS_TO_A_UNIQUE_IDENTIFICATION_NUMBER_ASSIGNED_TO_A_BUSINESS',
                                )}
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='VatRegistrationNumber'
                                level={t('VAT_REGISTRATION_NUMBER')}
                                marginBottom={20}
                                disabled={true}
                                rules={{
                                    required: true,
                                    pattern: RegexValidators.VAT_REGISTRATION_NUMBER,
                                }}
                                hideError={true}
                            >
                                {errors.VatRegistrationNumber?.type === 'required' && (
                                    <span className='error-message'>
                                        {t('VAT_REGISTRATION_NUMBER_IS_REQUIRED')}
                                    </span>
                                )}
                                {errors.VatRegistrationNumber?.type === 'pattern' && (
                                    <span className='error-message'>
                                        {watch('VatRegistrationNumber')?.length !== 15 ? (
                                            <>
                                                {t(
                                                    'INVALID_ENTRY_VAT_REGISTRATION_NUMBER_CONSISTS_OF_15_CHARACTERS_TOTAL',
                                                )}
                                            </>
                                        ) : (
                                            <>{t('VAT_REGISTRATION_NUMBER_PATTERN_ERROR')}</>
                                        )}
                                    </span>
                                )}
                            </InlineGenericInput>
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='TaxScheme'
                                level={t('TAX_SCHEME')}
                                marginBottom={20}
                                disabled={true}
                            />
                        </Col>
                    </Row>
                    <CoreTitle tag='h4' text={t('POSTAL_ADDRESS')} />
                    <Row gutter={16}>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.BuildingNumber'
                                level={t('BUILDING_NUMBER')}
                                marginBottom={20}
                                type='buildingNumber'
                                rules={{
                                    required: true,
                                    maxLength: {
                                        value: 4,
                                        message: t('BUILDING_NUMBER_MUST_BE_4_DIGITS'),
                                    },
                                    minLength: {
                                        value: 4,
                                        message: t('BUILDING_NUMBER_MUST_BE_4_DIGITS'),
                                    },
                                    pattern: {
                                        value: RegexValidators.ONLY_NUMERIC,
                                        message: t('BUILDING_NUMBER_SHOULD_BE_NUMERIC'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.AdditionalNumber'
                                level={t('ADDITIONAL_NUMBER')}
                                rules={{
                                    maxLength: {
                                        value: 4,
                                        message: t('ADDITIONAL_NUMBER_MUST_BE_4_DIGITS'),
                                    },
                                }}
                                marginBottom={20}
                                type='number'
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <GenericStreetName
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.Street'
                                level={t('STREET_ARABIC')}
                                marginBottom={20}
                                type='arabic'
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED_IN_STREET'),
                                    },
                                    maxLength: 1000,
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <GenericStreetName
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.StreetEn'
                                level={t('STREET_ENGLISH')}
                                marginBottom={20}
                                type='english'
                                rules={{
                                    required: true,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED_IN_STREET'),
                                    },
                                    maxLength: 1000,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.AdditionalStreet'
                                level={t('ADDITIONAL_STREET_ARABIC')}
                                marginBottom={20}
                                maxLimit={127}
                                type='arabic'
                                rules={{
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.AdditionalStreetEn'
                                level={t('ADDITIONAL_STREET_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.City'
                                level={t('CITY_ARABIC')}
                                marginBottom={20}
                                maxLimit={127}
                                type='arabic'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.CityEn'
                                level={t('CITY_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.District'
                                level={t('DISTRICT_ARABIC')}
                                marginBottom={20}
                                maxLimit={127}
                                type='arabic'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.DistrictEn'
                                level={t('DISTRICT_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.ProvinceOrState'
                                level={t('PROVINCE_ARABIC')}
                                marginBottom={20}
                                maxLimit={127}
                                type='arabic'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <GenericInputMaxLimit
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.ProvinceOrStateEn'
                                level={t('PROVINCE_ENGLISH')}
                                marginBottom={20}
                                maxLimit={127}
                                type='english'
                                rules={{
                                    required: true,
                                    maxLength: 127,
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <GenericPostalCode
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.PostalCode'
                                level={t('POSTAL_CODE')}
                                marginBottom={20}
                                rules={{
                                    required: true,
                                    minLength: {
                                        value: 5,
                                        message: 'Postal Code minimum length is 5 digit',
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericSelect
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Address.CountryCode'
                                level={t('COUNTRY')}
                                placeHolder={t('SELECT_COUNTRY')}
                                options={countryOptions}
                                rules={{
                                    required: true,
                                }}
                                setFormValue={setFormValue}
                            />
                        </Col>
                    </Row>

                    <CoreTitle tag='h4' text={t('CONTACT')} />
                    <Row gutter={16}>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.FirstName'
                                level={t('FIRST_NAME_ARABIC')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.FirstNameEn'
                                level={t('FIRST_NAME_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.LastName'
                                level={t('LAST_NAME_ARABIC')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.LastNameEn'
                                level={t('LAST_NAME_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.MiddleName'
                                level={t('MIDDLE_NAME_ARABIC')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.MiddleNameEn'
                                level={t('MIDDLE_NAME_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.JobTitle'
                                level={t('JOB_TITLE_ARABIC')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ARABIC_CHARACTER,
                                        message: t('ONLY_ARABIC_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Contact.JobTitleEn'
                                level={t('JOB_TITLE_ENGLISH')}
                                marginBottom={20}
                                rules={{
                                    pattern: {
                                        value: RegexValidators.ENGLISH_CHARACTER,
                                        message: t('ONLY_ENGLISH_TEXT_IS_ALLOWED'),
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Email'
                                level={t('EMAIL_ADDRESS')}
                                marginBottom={20}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t('EMAIL_FIELD_IS_REQUIRED'),
                                    },
                                    pattern: {
                                        value: RegexValidators.EMAIL,
                                        message: t('EMAIL_WARNING'),
                                    },
                                }}
                            />
                        </Col>
                        <Col span={12}>
                            <InlineGenericInput
                                control={control}
                                errors={errors}
                                levelCol={24}
                                inputCol={24}
                                dataKey='Fax'
                                level={t('FAX')}
                                marginBottom={20}
                            />
                        </Col>
                    </Row>
                    <div className='update-entity-buttons'>
                        <CoreButton text={t('UPDATE')} type='primary' htmlType={'submit'} />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default OrganizationEntityUpdate;
