import './PlanDetails.scss';
import { CoreButton, CoreTitle } from '@packages';

const PlanDetails = () => {
    return (
        <div className="round-box-shadow plan-details-card">
            <div className="plan-details-header">
                <CoreTitle tag='h4' text="Plan Details" />
                <CoreButton text="EDIT" size="small" />
            </div>
            <div className="plan-details-content">
                <p className="plan-name">Pay as you GO</p>
            </div>
        </div>
    );
};

export default PlanDetails;
