import React, { useState, useEffect } from 'react';
import './EinvoicePhase.scss';
import type { ColumnsType } from 'antd/es/table';
import { Table, Radio, Row, Col, message } from 'antd';
import { CheckOutlined, LeftOutlined, MinusOutlined } from '@ant-design/icons';
import { CoreButton, CoreTitle } from '@packages';
import { IOrganizationPhasePayload } from '@models/organization.model';
import { OrganizationAPI } from '@service/organization.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { useAppDispatch } from '@store/hooks';
import { getOrganization } from '@store/slices/organizationSlice';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { TFunction } from 'i18next';

interface EinvoicePhaseTableData {
    key: React.Key;
    Features: string;
    Phase_1: string;
    Phase_2: string;
}

const columns = (t: TFunction<'translation', undefined>): ColumnsType<EinvoicePhaseTableData> => [
    {
        title: t('FEATURES'),
        dataIndex: 'Features',
        render: (text) => <div className='invoice-feature-data'>{text}</div>,
    },
    {
        title: t('PHASE_ONE'),
        dataIndex: 'Phase_1',
        render: (text) => (
            <div className='invoice-table-data'>
                {text === 'uncheck' ? (
                    <MinusOutlined />
                ) : text === 'Check' ? (
                    <CheckOutlined className='check' />
                ) : (
                    text
                )}
            </div>
        ),
    },
    {
        title: t('PHASE_TWO'),
        dataIndex: 'Phase_2',
        render: (text) => (
            <div className='invoice-table-data'>
                {text === 'uncheck' ? (
                    <MinusOutlined />
                ) : text === 'Check' ? (
                    <CheckOutlined className='check' />
                ) : (
                    text
                )}
            </div>
        ),
    },
];

const data = (t: TFunction<'translation', undefined>): EinvoicePhaseTableData[] => [
    {
        key: '1',
        Features: t('ELECTRONIC_INVOICE'),
        Phase_1: 'Check',
        Phase_2: 'Check',
    },
    {
        key: '2',
        Features: t('BUSINESS_REGISTRATION'),
        Phase_1: 'Check',
        Phase_2: 'Check',
    },
    {
        key: '3',
        Features: t('INTEGRATION_WITH_ZATCA'),
        Phase_1: 'uncheck',
        Phase_2: 'Check',
    },
    {
        key: '4',
        Features: t('CRYPTOGRAPHIC_STAMP_GENERATION'),
        Phase_1: 'uncheck',
        Phase_2: 'Check',
    },
    {
        key: '5',
        Features: t('SUBMISSION_OF_INVOICE_TO_E_INVOICE_SOLUTIONS'),
        Phase_1: t('VAT_SUMMARY_ONCE_PER_MONTH'),
        Phase_2: t('FOR_B2B_INVOICES_THE_PROCESSING_TIME_IS_IN_REAL_TIME'),
    },
    {
        key: '6',
        Features: t('OQ_CODE'),
        Phase_1: t('INTERNAL_GENERATION_FOR_ALL_INVOICES'),
        Phase_2: t('PROVIDED_BY_E_INVOICE_SOLUTIONS_FOR_B2B_INVOICES'),
    },
    {
        key: '7',
        Features: t('CRYPTOGRAPHIC_STAMP_ON_PDF/A-3B_WITH_EMBEDDED_XML_ATTACHED'),
        Phase_1: 'uncheck',
        Phase_2: 'Check',
    },
];
const EinvoicePhase = () => {
    const { t } = useTranslation();
    const { organization } = useSelector((state: RootState) => state.organization);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { organizationId = '' } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const invoicePhase = searchParams.get('phase');

    const [phase, setPhase] = useState(0);

    useEffect(() => {
        setPhase(Number(organization?.InvoicePhase));
    }, [organization]);

    const handleSubmit = async () => {
        if (phase === 0) {
            message.warning('Select a Phase to continue');
        } else {
            const payload: IOrganizationPhasePayload = {
                OrganizationId: organization?.OrganizationId || '',
                Phase: phase,
            };
            const response = await OrganizationAPI.postOrganizationPhase(payload);
            if (response.Success) {
                dispatch(getOrganization(organizationId));
                navigate(`/organization-setup/${organizationId}/device`);
            }
        }
    };

    const prev = () => {
        navigate(`/organization-setup/${organizationId}/entity`);
    };

    const saveAndClose = () => {
        navigate(`/organizations`);
    };

    return (
        <>
            <div className='step-title-area'>
                <div className='left-area'>
                    <CoreButton text='' onClick={prev} icon={<LeftOutlined />} />
                    <div className='content'>
                        <CoreTitle tag='h6' text={t('ADD_ORGANIZATION_DETAILS')} />
                        <p>{t('STEP_3_of_5')}</p>
                    </div>
                </div>
                <div className='step-button-group'>
                    <CoreButton text={t('SAVE_&_CLOSED')} onClick={saveAndClose} />
                    <CoreButton type='primary' onClick={handleSubmit} text={t('CONTINUE')} />
                </div>
            </div>
            <div className='step-area'>
                <div className='stepper-content'>
                    <Row justify='center'>
                        <Col span={18}>
                            <div className='invoice-phase-wrapper'>
                                <div className='invoice-choose-phase'>
                                    <CoreTitle
                                        tag='h1'
                                        text={
                                            invoicePhase
                                                ? t('PHASE_SELECTION')
                                                : t('CHOOSE_A_PHASE')
                                        }
                                    />
                                    <div className='invoice-supporting-text'>
                                        <p>
                                            {t('BASED_ON_e_INVOICE_GUIDELISNES')}
                                            <br />
                                            {t('THE_DETAILS_OF_EACH_ARE_AVAILABLE_BELOW')}
                                        </p>
                                    </div>
                                    {invoicePhase ? (
                                        <div className='invoice-checkbox'>
                                            <p className='current-phase'>{t('PHASE_ONE')}</p>
                                            <Radio.Group
                                                name='radiogroup'
                                                onChange={(e) => setPhase(e.target.value)}
                                                defaultValue={organization?.InvoicePhase}
                                            >
                                                <Radio className='checkbox-text' value={2}>
                                                    {t('UPGRADE_TO_PHASE_TWO')}
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    ) : (
                                        <div className='invoice-checkbox'>
                                            <Radio.Group
                                                name='radiogroup'
                                                onChange={(e) => setPhase(e.target.value)}
                                                defaultValue={organization?.InvoicePhase}
                                            >
                                                <Radio className='checkbox-text' value={1}>
                                                    {t('PHASE')} 1
                                                </Radio>
                                                <Radio className='checkbox-text' value={2}>
                                                    {t('PHASE')} 2
                                                </Radio>
                                            </Radio.Group>
                                        </div>
                                    )}
                                </div>
                                <div className='invoice-table'>
                                    <Table
                                        columns={columns(t)}
                                        dataSource={data(t)}
                                        pagination={false}
                                        bordered
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default EinvoicePhase;
