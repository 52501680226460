/* eslint-disable react/display-name */
import './UploadInvoiceSelectFile.scss';
import { Col, Row } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import CSVFileUploadDragger from './../CSVFileUploader/CSVFileUploader';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    getAvailableOrganizations,
    selectAvailableOrganizationsInfo,
} from '@store/slices/organizationSlice';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { getActiveEntities, selectActiveEntityInfo } from '@store/slices/entitySlice';
import { getActiveDevices, selectActiveDeviceInfo } from '@store/slices/deviceSlice';
import { IOrganization } from '@models/organization.model';
import { unwrapResult } from '@reduxjs/toolkit';
import { uploadCSVFile } from '@store/slices/invoiceMappingSlice';

export interface UploadInvoiceSelectFileRef {
    onSubmitData: () => void;
}
export interface UploadInvoiceSelectFileProps {
    onNext: (response: any) => void;
    setLoading: (loading: boolean) => void;
}

const UploadInvoiceSelectFile = forwardRef<
    UploadInvoiceSelectFileRef,
    UploadInvoiceSelectFileProps
>((props, ref) => {
    const { t } = useTranslation();
    const availableOrganizationInfo = useAppSelector(selectAvailableOrganizationsInfo);
    const [organizationPhase, setOrganizationPhase] = useState<1 | 2>(1);
    const [file, setFile] = useState<File>();

    const entitiesInfo = useAppSelector(selectActiveEntityInfo);
    const devicesInfo = useAppSelector(selectActiveDeviceInfo);

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
        getValues,
    } = useForm<any>({
        mode: 'onChange',
        defaultValues: {},
    });

    const dispatch = useAppDispatch();

    const onSubmit = async () => {
        const values = getValues();
        if (!file) {
            return;
        }
        try {
            props.setLoading && props.setLoading(true);
            const response = await dispatch(uploadCSVFile({ file, data: values })).unwrap();
            props.onNext(response);
            props.setLoading && props.setLoading(false);
        } catch (error) {
            props.setLoading && props.setLoading(false);
        }
    };

    useImperativeHandle(ref, () => ({
        onSubmitData: () => {
            onSubmit();
        },
    }));

    const getAllOrgDependency = (orgId: string, organization: IOrganization) => {
        const phaseNumber = organization.InvoicePhase;
        setOrganizationPhase(phaseNumber as 1 | 2);
        if (phaseNumber == 2) {
            dispatch(getActiveEntities(orgId))
                .then(unwrapResult)
                .then((result) => {
                    if (result && result.length === 1) {
                        setFormValue('EntityId', result[0].EntityId || '');
                    }
                });

            dispatch(getActiveDevices(orgId))
                .then(unwrapResult)
                .then((result) => {
                    if (result && result.length === 1) {
                        setFormValue('DeviceId', result[0].DeviceId || '');
                    }
                });
        }
        setFormValue('EntityId', '');
        setFormValue('DeviceId', '');
    };

    const getAllFetchRequest = () => {
        dispatch(getAvailableOrganizations());
    };

    useEffect(() => {
        getAllFetchRequest();
    }, []);

    return (
        <div className='customer-select-file-wrapper'>
            <div className='customer-select-file-left'>
                <p className='download-message'>
                    Ensure a Seamless Import: Download a{' '}
                    <span className='download-link'>sample CSV</span> or
                    <span className='download-link'>XLS file</span> and perform a side-by-side
                    comparison with your import file to ensure its flawless suitability for the
                    import process.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-wrapper'>
                        <InlineGenericSelect
                            levelCol={8}
                            inputCol={16}
                            control={control}
                            errors={errors}
                            dataKey='OrganizationId'
                            level={t('ORGANIZATIONS')}
                            placeHolder={t('SELECT_ORGANIZATION')}
                            options={availableOrganizationInfo.data}
                            loading={availableOrganizationInfo.status === 'loading' ? true : false}
                            setFormValue={setFormValue}
                            onChange={getAllOrgDependency}
                            rules={{
                                required: true,
                            }}
                            marginBottom={0}
                            errorMessageSize='small'
                        />

                        {organizationPhase == 2 && (
                            <>
                                {entitiesInfo?.data?.length > 0 && (
                                    <InlineGenericSelect
                                        levelCol={8}
                                        inputCol={16}
                                        control={control}
                                        errors={errors}
                                        dataKey='EntityId'
                                        level={t('ENTITY')}
                                        options={entitiesInfo.data}
                                        loading={entitiesInfo.status === 'loading' ? true : false}
                                        setFormValue={setFormValue}
                                        rules={{
                                            required: entitiesInfo?.data?.length > 0,
                                        }}
                                        marginBottom={0}
                                        errorMessageSize='small'
                                    />
                                )}

                                <InlineGenericSelect
                                    levelCol={8}
                                    inputCol={16}
                                    control={control}
                                    errors={errors}
                                    dataKey='DeviceId'
                                    level={t('DEVICE')}
                                    placeHolder={t('SELECT_DEVICE')}
                                    options={devicesInfo.data}
                                    loading={devicesInfo.status === 'loading' ? true : false}
                                    setFormValue={setFormValue}
                                    rules={{
                                        required: false,
                                    }}
                                    marginBottom={0}
                                    errorMessageSize='small'
                                />
                            </>
                        )}
                        <Row gutter={100} className='margin-bottom-30'>
                            <Col span={8} className='customer-col'>
                                <label className='customer-label'>Upload file</label>
                            </Col>
                            <Col span={16}>
                                <Controller
                                    name='uploadFile'
                                    render={({ field }) => (
                                        <CSVFileUploadDragger
                                            onFileUpload={({ file }) => setFile(file)}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=''
                                    rules={{
                                        required: true,
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </form>
            </div>
        </div>
    );
});

export default UploadInvoiceSelectFile;
