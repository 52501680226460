import { Col, Row } from "antd";
import "./OverviewWrapper.scss";
import RunningInvoice from "../../running-invoice/RunningInvoice";
import PlanDetails from "../../plan-details/PlanDetails";
import PaymentMethod from "../../payment-method/PaymentMethod ";
import AvailableCreditsTable from "../../available-credits-table/AvailableCreditsTable";

const OverviewWrapper = () => {
    return <div className="overview-wrapper">
        <Row gutter={24}>
            <Col span={16}>
                <div className="left-panel">
                   <RunningInvoice />
                   <AvailableCreditsTable />
               </div>
            </Col>
            <Col span={8}>
                <div className="right-panel">
                    <PlanDetails />
                    <PaymentMethod />
                </div>
            </Col>
        </Row>
    </div>;
};
export default OverviewWrapper;
