import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import { useEffect, useMemo, useState } from 'react';
import { IAddDevicePayload, ILogoData } from '@models/organization.model';
import { ICountry } from '@models/generic.model';
import countryList from 'react-select-country-list';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getCountryNameFromCode } from '@utils/getCountryNameFromCode';
import { useForm } from 'react-hook-form';
import { defaultCountryCode } from '@config/default-value';
import { Col, message, Row } from 'antd';
import { INVOICE_TYPE_OPTIONS } from '@utils/InvoiceTypeOptions';
import { CoreBackButton, CoreButton, CoreTitle } from '@packages';
import InlineGenericInput from '@components/common/InlineGenericInput';
import { RegexValidators } from '@config/regex-validator-constant';
import InlineGenericSelect from '@components/common/InlineGenericSelect';
import './DeviceCreate.scss';
import { getDevices } from '@store/slices/deviceSlice';
import { DeviceAPI } from '@service/device.service';
import { useTranslation } from 'react-i18next';
import { getActiveEntities, selectActiveEntityInfo } from '@store/slices/entitySlice';
import GenericUnitName from '@components/common/GenericUnitName';
import GenericCheckbox from '@components/common/GenericCheckbox';
import { CoreFileLogo } from '@packages';
import { ILogoInfo } from 'packages/CoreFileCropper/CoreFileLogo';
import PhaseOneDeviceCreate from './PhaseOneDeviceCreate';
import { getSubscription } from '@store/slices/subscribeSlice';

const DeviceCreate = () => {
    const { t } = useTranslation();
    const { organization } = useSelector((state: RootState) => state.organization);
    const { status, data: activeEntity } = useAppSelector(selectActiveEntityInfo);
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);
    const [isLoadingPage, setLoadingPage] = useState<boolean>(false);
    const [logoInfo, setLogoInfo] = useState<ILogoInfo>({} as ILogoInfo);
    const isItTinNumber = () => {
        return organization?.VatRegistrationNumber[10] === '1';
    };

    const { organizationId = '' } = useParams();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    getCountryNameFromCode('BD');

    const {
        control,
        handleSubmit,
        reset,
        setValue: setFormValue,
        watch,
        formState: { errors },
    } = useForm<IAddDevicePayload>({
        mode: 'onTouched',
        defaultValues: {
            Country: defaultCountryCode,
            Location: defaultCountryCode,
            IsManualIRN: false,
        },
    });

    useEffect(() => {
        if (organizationId) {
            dispatch(getActiveEntities(organizationId));
            dispatch(getDevices(organizationId));
        }
    }, []);

    const goBack = () => {
        navigate(`/organizations/${organizationId}`, {
            state: {
                currentTab: 3,
                delay: true,
            },
        });
    };

    const onSubmit = async (data: IAddDevicePayload) => {
        const { url, height, width } = logoInfo || {};
        const Logo: ILogoData = {
            ShowOnPdf: data?.Logo?.ShowOnPdf ?? false,
            Data: url,
            Height: height,
            Width: width,
        };
        const payload: IAddDevicePayload = { ...data, Logo: Logo };
        !url && delete payload.Logo;
        payload.OrganizationId = organizationId;
        try {
            setLoadingPage(true);
            await DeviceAPI.addDevice(payload);
            message.success(t('DEVICE_SUCCESSFULLY_ADDED'));
            await dispatch(getSubscription());
            goBack();
            setLoadingPage(false);
            reset({
                Category: '',
                CommonName: '',
                Country: '',
                Email: '',
                EntityId: '',
                InvoiceType: '',
                Location: '',
                OrganizationUnitName: '',
                Otp: '',
                SerialNumber: '',
                OrganizationId: '',
                SimplifiedCreditNotePrefix: '',
                SimplifiedDebitNotePrefix: '',
                SimplifiedInvoicePrefix: '',
                StandardCreditNotePrefix: '',
                StandardDebitNotePrefix: '',
                StandardInvoicePrefix: '',
                IsManualIRN: false,
            });
        } catch {
            message.error(t('DEVICE_CREATE_FAILED'));
            setLoadingPage(false);
        }
    };

    const isSimplifiedBoth = watch('InvoiceType') === '0100' || watch('InvoiceType') === '1100';
    const isStandardBoth = watch('InvoiceType') === '1000' || watch('InvoiceType') === '1100';
    const IsManualIrn = watch('IsManualIRN');

    return (
        <div className='add-device'>
            <div className='device-title-area'>
                <CoreBackButton text={''} onClick={goBack} />
                <CoreTitle tag='h1' text={t('ADD_DEVICE')} />
            </div>
            {organization?.InvoicePhase === 1 ? (
                <PhaseOneDeviceCreate />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='add-device-form-wrapper'>
                        <Row gutter={16}>
                            <Col span={12}>
                                <InlineGenericInput
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='Email'
                                    level={t('EMAIL_ADDRESS')}
                                    placeHolder={t('EMAIL_ADDRESS')}
                                    marginBottom={20}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t('EMAIL_FIELD_IS_REQUIRED'),
                                        },
                                        pattern: {
                                            value: RegexValidators.EMAIL,
                                            message: t('INVALID_ENTRY_EMAIL.COM'),
                                        },
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <InlineGenericInput
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='SerialNumber'
                                    level={t('SERIAL_NUMBER')}
                                    marginBottom={20}
                                    rules={{
                                        required: true,
                                        pattern: {
                                            value: RegexValidators.SERIAL_NUMBER,
                                            message: 'Serial number pattern not matching',
                                        },
                                    }}
                                    tooltip='Example: 1-anything|2-anything|3-anything'
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <InlineGenericInput
                                    tooltip={t(
                                        'THE_COMMON_NAME_REFERS_TO_THE_EVERYDAY_OR_COMMONLY_USED_NAME_OF_YOUR_ORGANIZATION',
                                    )}
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='CommonName'
                                    level={t('COMMON_NAME')}
                                    marginBottom={20}
                                    rules={{
                                        required: true,
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                {isItTinNumber() ? (
                                    <InlineGenericInput
                                        tooltip={t(
                                            'THE_ORGANIZATION_TAX_IDENTIFICATION_NUMBER_TIN_IS_A_UNIQUE_IDENTIFICATION',
                                        )}
                                        control={control}
                                        errors={errors}
                                        levelCol={24}
                                        inputCol={24}
                                        dataKey='OrganizationUnitName'
                                        level={t('ORGANIZATION_TIN_NUMBER')}
                                        type={'otherSellerId'}
                                        marginBottom={20}
                                        rules={{
                                            required: true,
                                            pattern: RegexValidators.TIN_NUMBER,
                                        }}
                                    />
                                ) : (
                                    <GenericUnitName
                                        control={control}
                                        errors={errors}
                                        levelCol={24}
                                        inputCol={24}
                                        dataKey='OrganizationUnitName'
                                        level={t('ORGANIZATION_UNIT_NAME')}
                                        marginBottom={20}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <InlineGenericSelect
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='Country'
                                    level={t('COUNTRY')}
                                    options={countryOptions}
                                    rules={{
                                        required: true,
                                        max: 3,
                                        min: 2,
                                    }}
                                    setFormValue={setFormValue}
                                />
                            </Col>
                            <Col span={12}>
                                <InlineGenericSelect
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='Location'
                                    level={t('BUSINESS_LOCATION')}
                                    placeHolder='Select Business Location'
                                    options={countryOptions}
                                    rules={{
                                        required: true,
                                    }}
                                    setFormValue={setFormValue}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <InlineGenericInput
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='Category'
                                    level={t('BUSINESS_SECTOR')}
                                    placeHolder={t('BUSINESS_SECTOR')}
                                    marginBottom={20}
                                    rules={{
                                        required: true,
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <InlineGenericSelect
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='InvoiceType'
                                    level={t('INVOICE_TYPE')}
                                    placeHolder={t('SELECT_INVOICE_TYPE')}
                                    options={INVOICE_TYPE_OPTIONS}
                                    sorting={false}
                                    rules={{
                                        required: true,
                                    }}
                                    setFormValue={setFormValue}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <InlineGenericSelect
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='EntityId'
                                    level={t('ENTITY')}
                                    placeHolder={t('SELECT_ENTITY')}
                                    options={activeEntity}
                                    rules={{
                                        required: activeEntity.length > 0,
                                    }}
                                    loading={status === 'loading'}
                                    setFormValue={setFormValue}
                                />
                            </Col>
                            <Col span={12}>
                                <InlineGenericInput
                                    tooltip={t(
                                        'OTP_STANDS_FOR_ONE_TIME_PASSWORD_IT_IS_A_TEMPORARY_CODE_USED_FOR_VERIFYING',
                                    )}
                                    control={control}
                                    errors={errors}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='Otp'
                                    level={t('OTP')}
                                    marginBottom={20}
                                    type='number'
                                    rules={{
                                        required: true,
                                        maxLength: { value: 6, message: t('OTP_ERROR_MESSAGE') },
                                        minLength: { value: 6, message: t('OTP_ERROR_MESSAGE') },
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            {!IsManualIrn && (
                                <>
                                    {isSimplifiedBoth && (
                                        <>
                                            <Col span={12}>
                                                <InlineGenericInput
                                                    control={control}
                                                    errors={errors}
                                                    levelCol={24}
                                                    inputCol={24}
                                                    dataKey='SimplifiedInvoicePrefix'
                                                    level={t('SIMPLIFIED_INVOICE_PREFIX')}
                                                    marginBottom={20}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InlineGenericInput
                                                    control={control}
                                                    errors={errors}
                                                    levelCol={24}
                                                    inputCol={24}
                                                    dataKey='SimplifiedCreditNotePrefix'
                                                    level={t('SIMPLIFIED_CREDIT_NOTE_PREFIX')}
                                                    marginBottom={20}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InlineGenericInput
                                                    control={control}
                                                    errors={errors}
                                                    levelCol={24}
                                                    inputCol={24}
                                                    dataKey='SimplifiedDebitNotePrefix'
                                                    level={t('SIMPLIFIED_DEBIT_NOTE_PREFIX')}
                                                    marginBottom={20}
                                                />
                                            </Col>
                                        </>
                                    )}
                                    {isStandardBoth && (
                                        <>
                                            <Col span={12}>
                                                <InlineGenericInput
                                                    control={control}
                                                    errors={errors}
                                                    levelCol={24}
                                                    inputCol={24}
                                                    dataKey='StandardInvoicePrefix'
                                                    level={t('STANDARD_INVOICE_PREFIX')}
                                                    marginBottom={20}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InlineGenericInput
                                                    control={control}
                                                    errors={errors}
                                                    levelCol={24}
                                                    inputCol={24}
                                                    dataKey='StandardCreditNotePrefix'
                                                    level={t('STANDARD_CREDIT_NOTE_PREFIX')}
                                                    marginBottom={20}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <InlineGenericInput
                                                    control={control}
                                                    errors={errors}
                                                    levelCol={24}
                                                    inputCol={24}
                                                    dataKey='StandardDebitNotePrefix'
                                                    level={t('STANDARD_DEBIT_NOTE_PREFIX')}
                                                    marginBottom={20}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </>
                            )}
                            <Col className='checkbox-col' span={12}>
                                <GenericCheckbox
                                    tooltip={t(
                                        'CHECK_THIS_BOX_IF_YOU_WANT_TO_MANUALLY_CREATE_INVOICE_REFERENCE_NUMBER_IRNS',
                                    )}
                                    control={control}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='IsManualIRN'
                                    level={t('CREATE_MANUAL_IRN')}
                                />
                            </Col>
                            <Col className='checkbox-col' span={12}>
                                <GenericCheckbox
                                    control={control}
                                    levelCol={24}
                                    inputCol={24}
                                    dataKey='Logo.ShowOnPdf'
                                    level={t('Show on PDF')}
                                />
                            </Col>
                            <Col span={24}>
                                <div className='logo-title'>Logo Information</div>
                                <CoreFileLogo
                                    url={logoInfo.url}
                                    maxHeight={200}
                                    maxWidth={200}
                                    maxSize={800}
                                    onFileUpload={setLogoInfo}
                                />
                            </Col>
                        </Row>

                        <div className='add-device-buttons'>
                            <CoreButton
                                loading={isLoadingPage}
                                text={t('ADD')}
                                type='primary'
                                htmlType={'submit'}
                            />
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default DeviceCreate;
