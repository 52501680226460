import { useEffect, useMemo, useState } from 'react';
import { CoreBackButton, CoreButton } from '@packages';
import './OrganizationInitiate.scss';
import { OrganizationSetupLayoutWrapper } from '@components';
import { Controller, useForm } from 'react-hook-form';
import { Select, Spin } from 'antd';
import countryList from 'react-select-country-list';
import { IOrganizationInitiate } from '@models/organization.model';
import { OrganizationAPI } from '@service/organization.service';
import { useNavigate } from 'react-router-dom';
import { ICountry } from '@models/generic.model';
import { defaultCountryCode } from '@config/default-value';
import { RegexValidators } from '@config/regex-validator-constant';
import { getOrganizations } from '@store/slices/organizationSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import GenericLegalRegistrationName from '@components/common/GenericLegalRegistrationName';
import { getSubscription } from '@store/slices/subscribeSlice';

const OrganizationInitiate = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const countryOptions: ICountry[] = useMemo(() => countryList().getData(), []);

    const { detailsData } = useSelector((state: RootState) => state.subscribe);
    const { organizations, isLoading } = useSelector((state: RootState) => state.organization);
    const [hasOrganization, setHasOrganization] = useState(false);

    useEffect(() => {
        authorization();
    }, []);

    useEffect(() => {
        if (detailsData?.PlanName != 'ELITE' && organizations?.length >= 1) {
            navigate('/organizations');
        }
    }, [detailsData, organizations]);

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<IOrganizationInitiate>({
        mode: 'onChange',
        defaultValues: {
            OrganizationName: '',
            Location: defaultCountryCode,
        },
    });

    const onSubmit = async (data: IOrganizationInitiate) => {
        const response = await OrganizationAPI.postOrganizationInitiate(data);
        if (response.Success) {
            navigate(`/organization-setup/${response.Result}/organization-information`);
            dispatch(getSubscription());
        }
    };
    const dispatch = useAppDispatch();

    const authorization = async () => {
        await dispatch(getOrganizations())
            .then(unwrapResult)
            .then((organizations) => {
                if (organizations?.length < 1 || !organizations) {
                    setHasOrganization(true);
                } else if (
                    organizations.length === 1 &&
                    organizations[0]?.Workflow?.CurrentIndex >= 0 &&
                    organizations[0]?.Workflow?.CurrentIndex <= 3
                ) {
                    setHasOrganization(true);
                } else {
                    setHasOrganization(false);
                }
            });
    };

    return (
        <OrganizationSetupLayoutWrapper>
            <Spin size='large' spinning={isLoading}>
                <div className='organization-setup-back-button'>
                    {!hasOrganization && <CoreBackButton />}
                </div>
                <div className='organization-initiate-wrapper'>
                    <div className='organization-initiate-header'>
                        <p className='organization-initiate-title'>
                            {t('WELCOME_TO')} <br /> {t('E_INVOICE_SOLUTIONS')}
                        </p>
                        <p className='organization-initiate-sub-title'>
                            {t('PLEASE_ENTER_YOUR_DETAILS')}
                        </p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <GenericLegalRegistrationName
                            levelCol={24}
                            inputCol={24}
                            control={control}
                            errors={errors}
                            dataKey='OrganizationName'
                            level={t('ORGANIZATION_NAME_ARABIC')}
                            type='arabic'
                            rules={{
                                required: true,
                                pattern: {
                                    value: RegexValidators.ARABIC_CHARACTER,
                                    message: t('LEGAL_REGISTRATION_NAME_ONLY_ALLOW_ARABIC'),
                                },
                                maxLength: 1000,
                            }}
                            placeHolder={t('ORGANIZATION_NAME_ARABIC')}
                        />
                        <div className='general-input-wrapper'>
                            <label className='general-label'>{t('BUSINESS_LOCATION')}</label>
                            <Controller
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        style={{ width: '100%' }}
                                        showSearch
                                        placeholder={t('SELECT_A_PERSON')}
                                        optionFilterProp='children'
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        options={countryOptions}
                                    />
                                )}
                                control={control}
                                name='Location'
                            />
                        </div>
                        <div className='submit-button'>
                            <CoreButton
                                text={t('LETS_GET_STARTED')}
                                htmlType='submit'
                                type='primary'
                                isFullWidth={true}
                                disabled={!isValid}
                            />
                        </div>
                    </form>
                </div>
            </Spin>
        </OrganizationSetupLayoutWrapper>
    );
};

export default OrganizationInitiate;
