import './PaymentMethod.scss';
import { CoreButton, CoreTitle } from '@packages';

const PaymentMethod = () => {
    return (
        <div className="round-box-shadow payment-details-card">
            <div className="plan-details-header">
                <CoreTitle tag="h4" text="Payment Method" />
                <CoreButton text="EDIT" size="small" />
            </div>
            <div className="plan-details-content">
                <p className="plan-name">Ending in 1006</p>
                <p className="plan-date">Expires 10/27 - Added 09/02/24</p>
            </div>
        </div>
    );
};

export default PaymentMethod;
