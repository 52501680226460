/* eslint-disable react/display-name */
import { CoreTitle } from '@packages';
import './InvoiceMapField.scss';
import { Col, Row, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import InlineGenericSelect, { ISelectOption } from '@components/common/InlineGenericSelect';
import GenericCheckbox from '@components/common/GenericCheckbox';
import {
    creditNoteFields,
    customerDetailsFields,
    deliveryFields,
    documentLevelFields,
    invoiceDetailsFields,
    invoiceLineFields,
    paymentMeansFields,
    prepaymentFields,
    taxExemptionsFields,
} from '../../constant';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    mapReferenceId,
    selectGetUploadCsvConfigOptions,
    selectHeaderOptions,
    uploadCSVConfigFileById,
} from '@store/slices/invoiceMappingSlice';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { displayMapInvoiceHeaders, mapInvoiceHeaders } from '@utils/formatInvoiceHeader';
import {
    saveMapping,
    setMappingPayload,
} from '@store/slices/invoiceMappingSlice';
import InlineGenericInput from '@components/common/InlineGenericInput';

export interface InvoiceMapFieldRef {
    onSubmitMaps: () => void;
}

export interface InvoiceMapProps {
    onNext: (response: any) => void;
    setLoading: (loading: boolean) => void;
}

const InvoiceMapField = forwardRef<InvoiceMapFieldRef, InvoiceMapProps>((props, ref) => {
    const mappingOptions = useAppSelector(selectHeaderOptions);
    const referenceId = useAppSelector(mapReferenceId);
    const uploadCsvConfigs = useAppSelector(selectGetUploadCsvConfigOptions);
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        getValues,
        watch,
        setValue: setFormValue,
        formState: { errors },
    } = useForm<any>({ mode: 'onChange' });
    const onSubmit = async () => {
        const values = getValues();
        const reUsable = values?.reUsable || false;

        const payload = {
            InvoiceAllowanceHeaders: mapInvoiceHeaders(documentLevelFields, values),
            InvoiceCustomerHeaders: mapInvoiceHeaders(customerDetailsFields, values),
            InvoiceDeliveryHeaders: mapInvoiceHeaders(deliveryFields, values),
            InvoiceLineHeaders: mapInvoiceHeaders(invoiceLineFields, values),
            InvoicePaymentMeansHeaders: mapInvoiceHeaders(paymentMeansFields, values),
            InvoicePrepaymentHeaders: mapInvoiceHeaders(prepaymentFields, values),
            InvoiceReferenceHeaders: mapInvoiceHeaders(creditNoteFields, values),
            InvoiceRootHeaders: mapInvoiceHeaders(invoiceDetailsFields, values),
            InvoiceTaxExemptHeaders: mapInvoiceHeaders(taxExemptionsFields, values),

            MapConfigurationName: values?.MapConfigurationName || '',
            ReUsable: reUsable,
            ReferenceId: referenceId,
            ...(reUsable && { MapConfigurationId: values?.MapConfigurationId }),
        };

        dispatch(setMappingPayload(payload));

        try {
            const result = await dispatch(saveMapping(payload)).unwrap();
            props.setLoading && props.setLoading(false);
            props.onNext(result);
        } catch (error) {
            console.error('Error saving mapping:', error);
            // Handle the error accordingly, e.g., show a notification to the user
        }
    };

    useImperativeHandle(ref, () => ({
        onSubmitMaps: handleSubmit(onSubmit),
    }));

    const onUploadConfigChange = async (value: string, option: ISelectOption) => {
        if (!value) return;
        setLoading(true);
        dispatch(uploadCSVConfigFileById(value))
            .unwrap()
            .then((response) => {
                const {
                    InvoiceAllowanceHeaders,
                    InvoiceCustomerHeaders,
                    InvoiceDeliveryHeaders,
                    InvoiceLineHeaders,
                    InvoicePaymentMeansHeaders,
                    InvoicePrepaymentHeaders,
                    InvoiceReferenceHeaders,
                    InvoiceRootHeaders,
                    InvoiceTaxExemptHeaders,
                } = response;
                const formValues = {
                    ...displayMapInvoiceHeaders(InvoiceAllowanceHeaders),
                    ...displayMapInvoiceHeaders(InvoiceCustomerHeaders),
                    ...displayMapInvoiceHeaders(InvoiceDeliveryHeaders),
                    ...displayMapInvoiceHeaders(InvoiceLineHeaders),
                    ...displayMapInvoiceHeaders(InvoicePaymentMeansHeaders),
                    ...displayMapInvoiceHeaders(InvoicePrepaymentHeaders),
                    ...displayMapInvoiceHeaders(InvoiceReferenceHeaders),
                    ...displayMapInvoiceHeaders(InvoiceRootHeaders),
                    ...displayMapInvoiceHeaders(InvoiceTaxExemptHeaders),
                    MapConfigurationName: response.MapConfigurationName,
                    MapConfigurationId: value,
                };

                Object.entries(formValues).forEach(([key, value]) => {
                    setFormValue(key, value);
                });
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <Spin spinning={isLoading}>
            <div className='customer-map-field-wrapper'>
                <div className='selected-device'>
                    <p>
                        Your Selected File :{' '}
                        <span className='file-name'>Samsung R&D - batch 3.csv</span>
                    </p>
                    <p>
                        The best match to each field on the selected file have been auto-selected.
                    </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='customer-map-field-form'>
                        <CoreTitle tag='h5' text='Invoice Details' />

                        <Row gutter={130} align='middle' className='margin-bottom-16'>
                            <Col span={8}>
                                <p>Predefined Data Headers</p>
                            </Col>
                            <Col span={16}>
                                <p>Imported file headers</p>
                            </Col>
                        </Row>

                        <div className='margin-top-40 margin-bottom-30'>
                            <div className='customer-map-field-form'>
                                <div className='dropdown-list-item'>
                                    <GenericCheckbox
                                        control={control}
                                        levelCol={24}
                                        inputCol={24}
                                        dataKey='reUsable'
                                        level='Save these selections for use during future imports.'
                                        marginBottom={0}
                                    />

                                    {watch('reUsable') && (
                                        <div style={{ marginTop: 20 }}>
                                            <InlineGenericSelect
                                                key={'MapConfigurationId'}
                                                control={control}
                                                errors={errors}
                                                levelCol={8}
                                                inputCol={16}
                                                dataKey={'id'}
                                                level={'Map Config ID'}
                                                options={uploadCsvConfigs.data || []}
                                                loading={uploadCsvConfigs.isLoading}
                                                setFormValue={setFormValue}
                                                marginBottom={0}
                                                errorMessageSize='small'
                                                rules={{
                                                    required: true,
                                                }}
                                                allowClear
                                                onChange={onUploadConfigChange}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <InlineGenericInput
                            control={control}
                            errors={errors}
                            levelCol={8}
                            inputCol={16}
                            dataKey='MapConfigurationName'
                            level='Map Configuration Name'
                            rules={{
                                required: true,
                            }}
                            marginBottom={15}
                            errorMessageSize='small'
                        />

                        {invoiceDetailsFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                        <CoreTitle
                            tag='h5'
                            text='Invoice Line Items'
                            className='margin-top-20 margin-bottom-20'
                        />
                        {invoiceLineFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                        <CoreTitle
                            tag='h5'
                            text='Prepayment'
                            className='margin-top-20 margin-bottom-20'
                        />
                        {prepaymentFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                        <CoreTitle
                            tag='h5'
                            text='Delivery'
                            className='margin-top-20 margin-bottom-20'
                        />
                        {deliveryFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                        <CoreTitle
                            tag='h5'
                            text='Credit Note (Required all if Credit note)'
                            className='margin-top-20 margin-bottom-20'
                        />
                        {creditNoteFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                        <CoreTitle
                            tag='h5'
                            text='Customer Details (Required if Customer Id not provided in CSV)'
                            className='margin-top-20 margin-bottom-20'
                        />
                        {customerDetailsFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                        <CoreTitle
                            tag='h5'
                            text='Document Level Allowance'
                            className='margin-top-20 margin-bottom-20'
                        />
                        {documentLevelFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                        <CoreTitle
                            tag='h5'
                            text='Payment Means'
                            className='margin-top-20 margin-bottom-20'
                        />
                        {paymentMeansFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                        <CoreTitle
                            tag='h5'
                            text='Tax Exemptions (Required if any invoice has tax exempted tax category)'
                            className='margin-top-20 margin-bottom-20'
                        />
                        {taxExemptionsFields.map((data) => (
                            <InlineGenericSelect
                                key={data.key}
                                control={control}
                                errors={errors}
                                levelCol={8}
                                inputCol={16}
                                dataKey={data.key}
                                level={data.label}
                                options={mappingOptions}
                                setFormValue={setFormValue}
                                marginBottom={5}
                                errorMessageSize='small'
                                rules={{
                                    required: data.isRequired,
                                }}
                            />
                        ))}
                    </div>
                </form>
            </div>
        </Spin>
    );
});

export default InvoiceMapField;
