import "./RunningInvoice.scss";
import { Col, Divider, Row } from "antd";
import { CoreButton, CoreTitle } from "@packages";

const RunningInvoice = () => {
    return (
        <div className="round-box-shadow running-invoice-wrapper">
            <Row gutter={24}>
                <Col span={12}>
                    <CoreTitle tag='h4' text="Running Invoice Total" />
                    <CoreTitle tag='h3' text="$0.12" />
                    <div className="invoice-details-wrapper">
                        <div className="invoice-details">
                            <div className="details-block">
                                <CoreTitle tag='h5' text="Billing Period" />
                                <p>10/01/24 - 11/01/2024</p>
                            </div>
                            <div className="details-block">
                                <CoreTitle tag='h5' text="Last Billed on" />
                                <p>10/01/24</p>
                            </div>
                        </div>
                        <div className="invoice-details">
                            <div className="details-block">
                                <CoreTitle tag='h5' text="Previous Invoice" />
                                <p>$0.12</p>
                            </div>
                            <div className="details-block">
                                <CoreTitle tag='h5' text="Previous Invoice" />
                                <p>$0.12</p>
                            </div>
                        </div>
                    </div>
                    <CoreButton text="View Current Invoices" isFullWidth={true} />
                </Col>
                <Divider type="vertical" className="gray-divider"/>
                <Col span={11}>
                    <div className="description-wrapper">
                        <div className="description-summary">
                            <CoreTitle tag='h6' text="Description" />
                            <CoreTitle tag='h6' text="Running Total" />
                        </div>
                        <div className="pricing-summary">
                            <div className="pricing-summary-header">
                                <CoreTitle tag='h6' text="Pay as you GO" />
                                <CoreTitle tag='h6' text="$0.12" />
                            </div>
                            <div className="pricing-summary-item">
                                <p>Invoices</p>
                                <p>$0.00</p>
                            </div>
                            <div className="pricing-summary-item">
                                <p>Organizations</p>
                                <p>$0.00</p>
                            </div>
                            <div className="pricing-summary-item">
                                <p>Entities</p>
                                <p>$0.12</p>
                            </div>
                            <div className="pricing-summary-item">
                                <p>Devices</p>
                                <p>$0.00</p>
                            </div>
                            <div className="pricing-summary-item">
                                <p>Users</p>
                                <p>$0.00</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default RunningInvoice;