
import { Table, Progress } from 'antd';
import './AvailableCreditsTable.scss';
import { CoreButton, CoreTitle } from "@packages";

const AvailableCreditsTable = () => {
    const columns = [
        {
            title: 'Details',
            dataIndex: 'details',
            render: (details: any) => (
                <div style={{display: "flex", flexDirection: "column", gap:"4px"}}>
                    <span className="promo-text">PROMO</span>
                    <span>{details}</span>
                </div>
            ),

        },
        {
            title: 'Date Added',
            dataIndex: 'dateAdded',

        },
        {
            title: 'Active Dates',
            dataIndex: 'activeDates',
            render: (available: any) => (
                <div className="column-layout">
                    <span>{available}</span>
                    <span>(EXPIRES {available})</span>
                </div>
            ),

        },
        {
            title: 'Total Amount',
            dataIndex: 'totalAmount',

        },
        {
            title: 'Used',
            dataIndex: 'used',

        },
        {
            title: 'Available',
            dataIndex: 'available',
            render: (available: any) => (
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <span>{available}</span>
                    <Progress
                        percent={available}
                        size="small"
                        showInfo={false}
                        strokeColor="#AAC7B5"
                        className="progress-bar"
                    />
                </div>
            ),
        },
    ];

    const data = [
        {
            key: '1',
            details: 'GETXXXXXX',
            dateAdded: '09/02/24',
            activeDates: '09/02/24',
            expireDates: '09/02/26',
            totalAmount: 100.00,
            used: 1.46,
            available: 98.54,
        },
        {
            key: '2',
            details: 'QAAXXXXXX',
            dateAdded: '09/02/24',
            activeDates: '09/02/24',
            expireDates: '09/02/2409/02/26',
            totalAmount: 10.00,
            used: 0.00,
            available: 10.00,
        },
    ];

    return (
        <div className="round-box-shadow available-credits-table">
            <div className="table-header">
                <CoreTitle tag='h4' text="Available Credits" />
                <CoreButton text="Apply Code" />
            </div>
            <Table columns={columns} dataSource={data} pagination={false} />
            <p className="disclaimer">
                *We will draw down on the credits in the order that it appears (from top to bottom).
            </p>
        </div>
    );
};

export default AvailableCreditsTable;
