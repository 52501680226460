import './Payment.scss';
import { Card, Col, Row } from 'antd';
import { CoreButton, CoreTitle } from '@packages';
import { CreditCardOutlined, GlobalOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import AddPayment from './../add-payment/AddPayment';
import CardList from '../card/CardList';
import { useAppSelector } from '@store/hooks';
import { selectCurrentSubscriptions, subscribe } from '@store/slices/subscribeSlice';
import { useTranslation } from 'react-i18next';
import { calculateTimeDifference } from '@utils/calculateDayDifference';
import { selectIsPaymentFound } from '@store/slices/paymentSlice';
import PaymentHistory from './payment-history/PaymentHistory';

const Payment = () => {
    const { t } = useTranslation();
    const isPaymentFound = useAppSelector(selectIsPaymentFound);
    const [isAddPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(false);
    const { IsTrial, Expiration, TierName } = useAppSelector(selectCurrentSubscriptions) || {};
    const expireDayCount = calculateTimeDifference(Expiration);
    const isExpireSubscription = expireDayCount <= 0;
    const { detailsData: subscription } = useAppSelector(subscribe);

    const navigate = useNavigate();

    return (
        <div className='payment-wrapper'>
            <CoreTitle tag='h5' text={t('PAYMENT_METHOD')} />
            <Card className='payment-card-wrapper'>
                <Row>
                    <Col span={18}>
                        <div className='payment-card-description'>
                            <CreditCardOutlined className='payment-card-icons' />
                            <div className='payment-card-names'>
                                <CoreTitle
                                    tag='h5'
                                    text={
                                        isPaymentFound
                                            ? t('YOUR_PAYMENT_METHOD_IS_UP_TO_DATE')
                                            : t('NO_PAYMENT_METHOD_DETECTED')
                                    }
                                />
                                {isPaymentFound && <p>{t('YOU_CAN_CHANGE_IT_TO_ANOTHER_CARD')}</p>}
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className='payment-card-actions'>
                            <CoreButton
                                type='basic'
                                text={t('ADD_PAYMENT_METHOD')}
                                onClick={() => setAddPaymentModalOpen(true)}
                            />
                            <AddPayment
                                isOpenModal={isAddPaymentModalOpen}
                                onCancelModal={() => setAddPaymentModalOpen(false)}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>

            <CardList />

            <div className='margin-top-24'>
                <CoreTitle tag='h5' text={t('SUBSCRIPTION_DETAILS')} />
                <Card className='payment-card-wrapper'>
                    <Row>
                        <Col span={18}>
                            <div className='payment-card-description'>
                                <GlobalOutlined className='payment-card-icons' />
                                <div className='payment-card-names'>
                                    <CoreTitle
                                        tag='h6'
                                        text={subscription?.TierName || t('TRIAL')}
                                    />
                                    {IsTrial ? (
                                        <p>
                                            {isExpireSubscription
                                                ? 'Trail Expired'
                                                : `${t('TRAIL_EXPIRES_ON')} ${expireDayCount} ${t(
                                                      'DAYS',
                                                  )}`}
                                        </p>
                                    ) : (
                                        <p>
                                            {t('SUBSCRIPTION')}: {TierName}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className='payment-card-actions'>
                                {/*<CoreButton type='secondary' text='Cancel' onClick={() => {*/}
                                {/*    navigate('/subscribe/cancel-auto-renewal')*/}
                                {/*}} />*/}
                                <CoreButton
                                    type='basic'
                                    text={t('MANAGE_SUBSCRIPTION')}
                                    onClick={() => {
                                        navigate('/subscribe/pricing-plans');
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card>
                <PaymentHistory />
            </div>
        </div>
    );
};

export default Payment;
