/* eslint-disable react/display-name */
import { message } from 'antd';
import './UploadInvoiceSelect.scss';
import { useAppSelector } from "@store/hooks";
import { forwardRef, useImperativeHandle } from "react";
import {
    mapConfigId,
    mapReferenceId,
} from "@store/slices/invoiceMappingSlice";
import { INVOICE_API } from "@service/invoice.service";
import { CoreTitle } from "@packages";

export interface PreviewHeaderRef {
    onSubmitPreview: () => void;
}

export interface PreviewHeaderProps {
    onNext: (response: any) => void;
    setLoading: (loading: boolean) => void;
}

const UploadInvoiceSelect = forwardRef<PreviewHeaderRef, PreviewHeaderProps>((props, ref) => {
    const mappingPayload = useAppSelector(state => state.invoiceMapping.mappingPayload);
    const referenceId = useAppSelector(mapReferenceId);
    const ConfigId = useAppSelector(mapConfigId);

    const submitInvoices = async () => {
        const payload = {
            ConfigId: ConfigId,
            ReferenceId: referenceId,
        };

        try {
            const result = await INVOICE_API.submitInvoices(payload);
            props.setLoading && props.setLoading(false);
            message.success("Invoice Mapping Submitted");
        } catch (error) {
            console.error('Error saving mapping:', error);
            message.error("Invoice Mapping Submission Failed");
            // Handle the error accordingly, e.g., show a notification to the user
        }
    }

    useImperativeHandle(ref, () => ({
        onSubmitPreview: () => {
            submitInvoices();
        },
    }));

    const headerCategories = [
        'InvoiceAllowanceHeaders',
        'InvoiceCustomerHeaders',
        'InvoiceDeliveryHeaders',
        'InvoiceLineHeaders',
        'InvoicePaymentMeansHeaders',
        'InvoicePrepaymentHeaders',
        'InvoiceReferenceHeaders',
        'InvoiceRootHeaders',
        'InvoiceTaxExemptHeaders'
    ];

    return (
        <div className='customer-invoice-select-wrapper'>
            <div>
                {headerCategories.map(category => (
                    mappingPayload?.[category]?.length > 0 && (
                        <div key={category} className="overview-wrapper">
                            <CoreTitle tag='h3' text={category} />
                            {mappingPayload[category].map((header: { fileHeader: string; invoiceHeader: string }, index: number) => (
                                <p key={index} className="mapping-values">
                                    {header.fileHeader || 'empty'} - {header.invoiceHeader}
                                </p>
                            ))}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
});

export default UploadInvoiceSelect;
