import './PricingPlans.scss';
import { CoreBackButton, CoreTitle } from '@packages';
import { Tabs } from 'antd';
import PriceCard from '../../components/annual-price/AnnualPrice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PricingPlans = () => {
    const { t } = useTranslation();
    const tabItems = [
        {
            label: t('MONTHLY_PRICE'),
            key: '1',
            children: <PriceCard type='month' />,
        },
        {
            label: t('ANNUAL_PRICE'),
            key: '2',
            children: <PriceCard type='year' />,
        },
    ];

    const navigate = useNavigate();

    return (
        <div className='pricing-plans-wrapper'>
            <CoreBackButton
                text={''}
                onClick={() => {
                    navigate(`/subscribe`, {
                        state: {
                            currentTab: 'payment',
                        },
                    });
                }}
            />
            <div className='pricing-plans-inner-wrapper'>
                <CoreTitle tag='h2' text={t('PRICING_PLAN')} />
                <p>{t('TRY_OUR_BASIC_PLAN_RISK_FREE_FOR_30_DAYS')}</p>
                <Tabs items={tabItems} centered={true} />
            </div>
        </div>
    );
};

export default PricingPlans;
